import OrdersList from "./list/OrdersList";


const Orders = () => {
    return ( <><OrdersList></OrdersList></> );
}
 
export default Orders;


